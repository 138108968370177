import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
//Icons
import GitHubIcon from '@material-ui/icons/GitHub';
import FindInPageIcon from '@material-ui/icons/FindInPage';
//Components
import Card from 'components/ImageCard/ImageCard';
import BackImage from 'components/BackImage';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    height: "100vh",
    fontFamily: 'Ubuntu, Arial',
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(15),
    },
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing(20),
    },
    [theme.breakpoints.up('xl')]: {
      paddingTop: theme.spacing(5),
    },
  },
  content: {
    width: '90%',
    margin: "auto",
  },
  group: {
    flexGrow: 1,
  },
  row:{
    marginTop: 0,
    marginBottom: "auto",
    height: "100%"
  },
  rows:{
    marginTop: "auto",
    marginBottom: "auto",
    height: "100%"
  },
  chip:{
    fontFamily: 'Ubuntu, Arial',
    marginRight: theme.spacing(1)
  },
  image:{
    [theme.breakpoints.up('sm')]: {
      backgroundPosition: "-60% 0",
    },
    [theme.breakpoints.up('md')]: {
      height: "60vh",
      backgroundPosition: "-40% 0",
    },
    [theme.breakpoints.up('lg')]: {
      height: "70vh",
      backgroundPosition: "-25% 0",
    },
    leftTitle: {
      fontWeight: 800,
      lineHeight: 1,
      fontSize: "3rem",
      [theme.breakpoints.up('md')]: {
        fontSize: "4rem",
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: "6rem",
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: "7rem",
      },
    },
    leftSubTitle: {
      fontWeight: 800,
      lineHeight: 1,
      fontSize: "5rem",
      [theme.breakpoints.up('md')]: {
        fontSize: "8rem",
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: "12rem",
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: "14rem",
      },
    },
    rowsTitle: {
      fontWeight: 500,
      lineHeight: 1,
      fontSize: "3rem",
      [theme.breakpoints.up('md')]: {
        fontSize: "4rem",
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: "6rem",
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: "7rem",
      },
    },
    rowsSubTitle:{
      fontWeight: 500,
      lineHeight: 1,
      fontSize: "1.5rem",
        marginTop: theme.spacing(3),
      [theme.breakpoints.up('md')]: {
        fontSize: "2rem",
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: "3rem",
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: "3.5rem",
      },
    },
  }
}));

export default function ComoFuncionaPage({...props}) {
  const classes = useStyles();
  const imageStyle = {...classes.image}

  return (
    <div className={classes.root}>
      <BackImage image="images/saiba-mais.svg" className={classes.image} customStyle={{backgroundPosition: "-25% 0", height: "70vh", align: "center"}} >
      <div className={classes.content}>
        <Grid container justify="center" className={classes.goup} spacing={8} >
            <Grid item xs={12} md={6} >
              <div className='leftTitle' align="center">Engaje sua equipe com uma rede social interna</div>
              <div className='leftSubTitle' align="center">Schematiq invests in the future with our sustainability and education initiatives.</div>
            </Grid>
            <Grid item xs={12} md={6} className={classes.rows}>
              <Grid container direction="row"
                justify="space-between"
                alignItems="center">
                <Grid item className={classes.row}>
                  <div className='rowsTitle' align="left">Faça chegar sua mensagem às pessoas certas</div>
                  <div className='rowsSubTitle' align="left">Talk about your company's CSR initiatives here.</div>
                  <Divider />
                </Grid>
                <Grid item className={classes.row}>
                  <div className='rowsTitle' align="left">Faça chegar sua mensagem às pessoas certas</div>
                  <div className='rowsSubTitle' align="left">Talk about your company's CSR initiatives here.</div>
                  <Divider />  
                </Grid>
                <Grid item className={classes.row}>
                  <div className='rowsTitle' align="left">Faça chegar sua mensagem às pessoas certas</div>
                  <div className='rowsSubTitle' align="left">Talk about your company's CSR initiatives here.</div>
                  <Divider />
                </Grid>
              </Grid>
            </Grid>              
        </Grid>
      </div>
      </BackImage>
    </div>
  );
}