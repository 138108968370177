import React from "react";
//@material-ui
import Hidden from '@material-ui/core/Hidden';
//Layout components
import Header from 'layout/NavBars/Header';
import Bottom from 'layout/NavBars/Bottom';
//Rotes components
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import HomePage from 'views/HomePage';

function App() {
  const navLinks = {home: "/", 
                    saibamais: {pathname: "/", hash: "#saiba-mais", scroll: "smoth"}, 
                    comofunciona: {pathname: "/", hash: "#como-funciona", scroll: "smoth"}, 
                    contato: {pathname: "/", hash: "#contato", scroll: "smoth"}
                  }

  return (
      <Router >
        <Hidden smDown implementation="css"><Header navLinks={navLinks}/></Hidden>
        <Switch>
          <Route path="/" component={() => <HomePage />} />
        </Switch>
        <Hidden smUp implementation="css"><Bottom navLinks={navLinks}/></Hidden>
      </Router>
  );
}

export default App;
