import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

const useStyles = theme => ({
  children:{
    width: "100%",
    [theme.breakpoints.up('md')]: {
       margin: "0 0 0 0",
    },
  },
  image: {
    maxHeight: "100%",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    padding: "0",
    border: "0",
    display: "flex",
    alignItems: "center",
  }
});

export class BackImage extends React.Component {
  
  render() {
    const {
      children,
      image,
      classes,
      customStyle,
    } = this.props;

    console.log(customStyle)
    

    return (
      
      <div
        className={classes.image}
        style={{
          backgroundImage: "url(" + image + ")",
          ...customStyle
        }}
        id="image"
      >
        <div className={classes.children} id="children">
          {children}
        </div>
      </div>
      
    );
  }
}

export default withStyles(useStyles)(BackImage);