import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
//Icons
import GitHubIcon from '@material-ui/icons/GitHub';
import FindInPageIcon from '@material-ui/icons/FindInPage';
//Components
import Card from 'components/ImageCard/ImageCard';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    minHeight: "80%",
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(20),
      paddingBottom: theme.spacing(20),
    },
  },
  content: {
    width: '90%',
    margin: "auto",
  },
  group: {
    flexGrow: 1,
  },
  chip:{
    fontFamily: 'Ubuntu, Arial',
    marginRight: theme.spacing(1)
  },
}));

export default function ComoFuncionaPage({...props}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Grid container justify="left" className={classes.goup} spacing={2} >
            <Grid item xs={12} md={6}>
              <Typography variant="h1" align="left">Engaje sua equipe com uma rede social interna</Typography>
              <Typography variant="h3" component="h2" align="left">Schematiq invests in the future with our sustainability and education initiatives.</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h3" align="left">Faça chegar sua mensagem às pessoas certas</Typography>
              <Typography variant="h4" align="left">Talk about your company's CSR initiatives here.</Typography>
            </Grid>              
        </Grid>
      </div>
    </div>
  );
}