import React from "react";
//@material-ui
import makeStyles from "@material-ui/core/styles/makeStyles";
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom'
import { useLocation } from "react-router-dom";
//Components
import CoverImage from 'components/CoverImage/CoverImage';
import SaibaMaisPage from 'views/SaibaMaisPage';
import ComoFuncionaPage from 'views/ComoFuncionaPage';
import ContatoPage from 'views/ContatoPage';
import QuemSomosPage from 'views/QuemSomosPage';
import { withTheme } from "@material-ui/core";
import TwoBackImages from 'components/TwoBackImages';



const useStyles = makeStyles(theme => ({
  root: {
    background: "#FFFFFF",
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
    [theme.breakpoints.up('xl')]: {
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(10),
    },
  },
  container: {
    position: "relative",
    height: "100%",
    width: "100%",
    zIndex: 10,
    fontFamily: 'Ubuntu, Arial',
    color: "#000000",
  },
  sections:{
    [theme.breakpoints.up('sm')]: {
      scrollMarginTop: "40px",
      scrollSnapMargin: "40px",
    },
    margin: "auto",
    display: "block",
  },
  button:{
    fontFamily: 'Ubuntu, Arial',
    marginTop: theme.spacing(2),
    textTransform: "none",
    fontSize: "1rem",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(10),
    color: '#FFFFFF',
    [theme.breakpoints.up('md')]: {
      fontSize: "2rem",
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: "3rem",
    },
    '&:hover': {
      textTransform: "uppercase",
    }
  },
  homeTitle: {
    fontWeight: 500,
    lineHeight: 1,
    position: "relative",
    fontSize: "3rem",
      marginTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      fontSize: "4rem",
      marginTop: theme.spacing(5),
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: "6rem",
      marginTop: theme.spacing(10),
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: "7rem",
      marginTop: theme.spacing(10),
    },
  },
  logo: {
    width: "100%",
    [theme.breakpoints.up('sm')]: {
      width: "60%",
    },
    [theme.breakpoints.up('md')]: {
      width: "50%",
    },
  },
  homeSubTitle: {
    fontWeight: 500,
    fontSize: "1rem",
    [theme.breakpoints.up('md')]: {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: "2rem",
    },
  },
  homeDescription: {
    fontWeight: 500,
    textTransform: "uppercase",
    maxWidth: "60%",
    fontSize: "0.5rem",
    [theme.breakpoints.up('md')]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: "1rem",
    },
  }
}));


function HomePage() {
  const classes = useStyles();
  const [trigger, setTrigger] = React.useState(false)
  const { hash } = useLocation();
  const sections = React.createRef();
  const saibamais = React.createRef();
  const comofunciona = React.createRef();
  const contato = React.createRef();
  const quemsomos = React.createRef();

  window.addEventListener("load", (event) => {
    createObserver(sections.current);
  }, false);

 

  function handleIntersect(entries, observer) {
    entries.forEach(entry => {
      if (entry.intersectionRatio > 0.1) {
        setTrigger(true)
      } 
    });
  }

  function createObserver(sectionsDiv) {
    let observer;
  
    let options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1
    };
  
    observer = new IntersectionObserver(handleIntersect, options);
    observer.observe(sectionsDiv);
  }

  const section = React.useCallback(
    (sec) => {
      switch (sec) {
        case 'saibamais':
          saibamais.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
          break;
        case 'comofunciona':
          comofunciona.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
          break;
        case 'contato':
          contato.current.scrollIntoView({ behavior: 'smooth'});
          break;
          case 'quemsomos':
            contato.current.scrollIntoView({ behavior: 'smooth'});
            break;
        default:
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
      }
    },
    [saibamais, comofunciona, contato],
  );

  React.useEffect(() => {
      section(hash.substring(1))
  }, [hash, section]);
  
  

    return (
      <div className={classes.root} id="root">
        <TwoBackImages 
          image1="images/home-back-1.svg" 
          image2="images/home-back-2.svg" 
          alt="background images" 
          customStyle={{
            backgroundPosition: "left bottom, 120% 30%",
            backgroundSize: "30%, 30%"}}>
          <Slide direction="down" in={true}  timeout={1500} mountOnEnter>
              <div className={classes.container} >
                <img src="images/logo.png" alt="Bravo logo" className={classes.logo}/>
                <div className={classes.homeTitle}>Comunicação corporativa simples e inteligente</div>
                <Button 
                  color="secondary" 
                  size="large" 
                  variant="contained"
                  classes={{root: classes.button}}
                  component={Link} to={{pathname: "/", hash: "#saibamais", scroll: "smoth"}}>CONHEÇA MAIS</Button>
              </div>
          </Slide>
        </TwoBackImages>            
        <div ref={sections}>
          <div ref={comofunciona} className={classes.sections}> <ComoFuncionaPage /></div>
          <div ref={saibamais} className={classes.sections}><SaibaMaisPage trigger={trigger} /></div>
          <div ref={contato} className={classes.sections}><ContatoPage /></div>
          <div ref={quemsomos} className={classes.sections}><QuemSomosPage /></div>
        </div>
      </div>
    );
  }

  
  export default HomePage;