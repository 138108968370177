import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import 'typeface-ubuntu';
import 'typeface-advent-pro';

let theme = createMuiTheme({
  typography: {
    fontFamily: "Ubuntu, Arial",
    body2: {
      fontFamily: "Ubuntu, Arial",
      fontSize: "1.3rem"
    }
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#0A5784',
      ligth: '#9581d8'
    },
    secondary: {
      main: '#e8992e',
    },
    detail: {
      main: '#9581d8',
    },
  }
});

theme = responsiveFontSizes(theme);


export default theme;