import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

const useStyles = theme => ({
  children:{
    width: "70%",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.up('md')]: {
      width: "70%",
    },
    [theme.breakpoints.up('lg')]: {
      width: "70%",
    },
    [theme.breakpoints.up('xl')]: {
      width: "70%",
    },
  },
  image: {
    margin: "0",
    maxHeight: "100%",
    width: "100%",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    padding: "0",
    border: "0",
    display: "flex",
    alignItems: "center",
  }
});

export class TwoBackImages extends React.Component {
  
  render() {
    const {
      children,
      image1,
      image2,
      classes,
      customStyle,
    } = this.props;

    console.log(customStyle)
    

    return (
      
      <div
        className={classes.image}
        style={{
          backgroundImage: "url(" + image1 + "), url(" + image2 + ")",
          ...customStyle
        }}
        id="image"
      >
        <div className={classes.children} id="children">
          {children}
        </div>
      </div>
      
    );
  }
}

export default withStyles(useStyles)(TwoBackImages);